<template>
  <div>
    <div class="mt-4 overflow-auto" :style="`max-height:` + mainHeight + `px !important;`">
      <div v-if="dashboardObj !== null" class="">
        <!-- <div class="min-w-min card p-4 mb-4 rounded-xl bg-white mt-4"> -->
        <div class="min-w-min card p-4 rounded-xl bg-white my-4"  v-if="dataBaseUsageValueInPer < 100">
          <h3 class="text-text2 heading-3 pb-1 font-semibold">Profile Status</h3>
          <div class="text-gray4 heading-4 px-2">Your profile is {{dataBaseUsageValueInPer}}% completed</div>
          <div class="w-full" >
            <div class="progress-bar rounded-lg h-3">
              <div class="progress-bar-value rounded-lg" :style="`width:` +  dataBaseUsageValueInPer + `%;`"></div>
            </div>
          </div>
          <div class="mt-2">
            <div class="p-1">
              <div v-if="dataBaseUsageValueInPer === 70" class="text-primary heading-5"><i class="fa-solid fa-circle-check pr-2"></i>Add a Organization Address.</div>
              <span @click="$router.push({name: 'OrgDetail'})" v-else class="text-gray4 heading-5 cursor-pointer"><i class="fa-solid fa-circle-xmark pr-2"></i>Add a Organization Address.</span>
            </div>
            <div class="p-1">
              <div v-if="dataBaseUsageValueInPer === 60" class="text-primary heading-5"><i class="fa-solid fa-circle-check pr-2"></i>Add a Quotation/Invoice Address.</div>
              <span @click="$router.push({name: 'OrgDetail'})" v-else class="text-gray4 heading-5 cursor-pointer"><i class="fa-solid fa-circle-xmark pr-2 "></i>Add a Quotation/Invoice Address.</span>
            </div>
            <div class="text-right">
              <span @click="$router.push({name: 'OrgDetail'})" class="text-primary heading-5 p-1 cursor-pointer">Complete your Profile</span>
            </div>
          </div>
        </div>
        <div class="p-4 card bg-white rounded-xl mb-4 text-text1">
            <h3 class="text-text2 heading-3 pb-1 font-semibold">Today's Schedule</h3>
          <div  class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
            <div class="dashboardBox" v-if="isJobPermission" @click.stop="$router.push({name: 'job'})">
                <!-- <div class="text-right mb-3">
                  <p class="  heading-5 group-hover:text-primary transition">Visits</p>
                  <div class="">
                    <div class=" font-semibold">{{dashboardObj.allJobOverview.totalVisits }}</div>
                  </div> 
                </div> -->
                <div class="flex justify-between mt-10">
                    <div>
                        Completed: {{dashboardObj.allJobOverview.totalcompletedVisit}} 
                    </div>
                    <div>
                        Ongoing: {{dashboardObj.allJobOverview.totalRunningVisits}}
                    </div>
                </div>
                <div class="card_icon dashboardIconBox">
                <span>Vsitis {{dashboardObj.allJobOverview.totalVisits }}</span>
                </div>
            </div>
            <div class="dashboardBox" v-if="isRequestPermission" @click.stop="$router.push({name: 'request'})">
                <!-- <div class="text-right mb-3">
                  <p class="  heading-5 group-hover:text-primary transition">Rquirement</p>
                  <div class="">
                    <div class=" font-semibold">{{dashboardObj.allJobOverview.totalRequests}}</div>
                  </div>
                </div> -->
                <div class="flex justify-between mt-10">
                    <div>
                        Completed: {{dashboardObj.allJobOverview.totalCompletedRequests}}
                    </div>
                    <div>
                        Remaining: {{dashboardObj.myJobOverview.todayNewRequests}}
                    </div>
                </div>
                <div class="card_icon dashboardIconBox">
                <span>Requirements {{dashboardObj.allJobOverview.totalRequests}}</span>
                </div>
                <!-- <div class="card_icon dashboardIconBox">
                <span><i class="fa-solid fa-code-pull-request h-8 text-primary"></i></span>
                </div> -->
            </div>
            <div class="dashboardBox" v-if="isTaskPermission" @click.stop="$router.push({name: 'Task'})">
                <!-- <div class="text-right mb-3">
                  <p class="  heading-5 group-hover:text-primary transition">Tasks</p>
                  <div class="">
                    <div class=" font-semibold">{{dashboardObj.allJobOverview.totalTasks}}</div>
                  </div>
                </div> -->
                <div class="flex justify-between mt-10">
                    <div>
                        Completed: {{dashboardObj.allJobOverview.totalCompletedTask}}
                    </div>
                    <div>
                        Open: {{ dashboardObj.myJobOverview.todayOpenTask >= 0  ? dashboardObj.myJobOverview.todayOpenTask : 0 }}
                    </div>
                </div>
                <div class="card_icon dashboardIconBox">
                <span>Tasks {{dashboardObj.allJobOverview.totalTasks}}</span>
                </div>
                <!-- <div class="card_icon dashboardIconBox">
                <span><i class="fa-solid fa-clipboard-list h-8 text-primary"></i></span>
                </div> -->
            </div>
            <div class="dashboardBox" v-if="isShiftPermission" @click.stop="$router.push({name: 'shift'})">
                <!-- <div class="text-right mb-3">
                  <p class="  heading-5 group-hover:text-primary transition">Shift</p>
                  <div class="">
                    <div class=" font-semibold">{{dashboardObj.shiftOverview.totalShift + dashboardObj.shiftOverview.notClockedIn}}</div>
                  </div>
                </div> -->
                <div class="flex justify-between mt-10">
                    <div>
                        Completed: {{dashboardObj.shiftOverview.shiftCompleted}}
                    </div>
                    <div>
                        Ongoing: {{dashboardObj.shiftOverview.totalShift}}
                    </div>
                </div>
                <div class="card_icon dashboardIconBox">
                <span>Shift {{dashboardObj.shiftOverview.totalShift + dashboardObj.shiftOverview.notClockedIn}}</span>
                </div>
                <!-- <div class="card_icon dashboardIconBox">
                <span><i class="fa-solid fa-clock h-8 text-primary"></i></span>
                </div> -->
            </div>
          </div>
        </div>
       <div class="grid xl:grid-cols-2 gap-4 text-text1">
          <div class="">
            <div class="p-4 card bg-white rounded-xl mb-4">
                    <h3 class="text-text2 heading-3 pb-1 font-semibold">Overview</h3>
                    <div class="grid grid-cols-2 sm:grid-cols-3 gap-4">
                        <div class=" border-r border-gray2">
                            <div class="mb-2">
                                <p>Open Quotes</p>
                                <p>{{dashboardObj.toDoOverview.allOpenQuoteCount}}</p>
                            </div>
                            <div>
                                <p>Expected Amount</p>
                                <p>{{dashboardObj.myJobOverview.totalOpenQuotesAmount | amountSpaceFormaterWithToFix  }}</p>
                            </div>
                        </div>
                        <div class=" 2xl:border-r border-gray2">
                            <div class="mb-2">
                                <p>Unpaid Invoices</p>
                                <p>{{dashboardObj.myJobOverview.totalInvoiceDueCount}}</p>
                            </div>
                            <div>
                                <p>Unpaid Amount</p>
                                <p>{{dashboardObj.myJobOverview.totalInvoiceDueAmount | amountSpaceFormaterWithToFix  }}</p>
                            </div>
                        </div>
                        <div class="mt-4">
                            <div class="mb-2">
                                <p>Open Leads</p>
                                <p>--</p>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="p-4 card bg-white rounded-xl">
                <h3 class="text-text2 heading-3 pb-1 font-semibold">Business Overview</h3>
                <div class="mt-2 flex justify-between border-b border-gray2">
                    <p class="text-primary">Invoices</p>
                    <div class="text-primary heading-3 cursor-pointer" @click="$router.push({name: 'createInvoice'})">
                        <i class="fa-solid fa-circle-plus"></i>
                    </div>
                </div>
                <div class="mt-2 flex justify-between border-b border-gray2 pb-1">
                    <div>
                        <p class="mb-1">Open</p>
                        <p>{{dashboardObj.openInvoiceCount}}</p>
                    </div>
                    <div>
                        <p class="mb-1">Overdue</p>
                        <p class=" text-right">{{dashboardObj.overdueInvCount}}</p>
                    </div>
                </div>
                <div class="mt-2 flex justify-between  pb-1">
                    <div>
                        <p class="mb-1">Partially Paid</p>
                        <p>{{dashboardObj.parshalyPaidInvoiceCount}}</p>
                    </div>
                </div>
            </div>
          </div>
          <div class="">
          <div class="p-4 card bg-white rounded-xl mb-4">
                <h3 class="text-error heading-3 pb-1 font-semibold">Action Required</h3>
                <div class="mt-2 flex justify-between border-b border-gray2">
                    <p class="text-primary">To Do//Action Required</p>
                    <div class="text-primary heading-3 cursor-pointer" @click="$router.push({name: 'addRequest', params: {requestId: 0}})">
                        <i class="fa-solid fa-circle-plus"></i>
                    </div>
                </div>
                <div class="mt-2 flex justify-between border-b border-gray2 pb-1">
                    <div>
                        <p class="mb-1">Unscheduled</p>
                        <p>{{dashboardObj.totalUnscheduleJobsAndRequests}}</p>
                    </div>
                    <div>
                        <p class="mb-1">New Requests</p>
                        <p class=" text-right">{{dashboardObj.toDoOverview.futureRequestsNotComplete}}</p>
                    </div>
                </div>
                <div class="mt-2 flex justify-between border-b border-gray2 pb-1">
                    <div>
                        <p class="mb-1">Assesment Completed</p>
                        <p>{{dashboardObj.toDoOverview.reqCompleteNotCreateQuoteCount}}</p>
                    </div>
                    <div>
                        <p class="mb-1">Approved Quotes</p>
                        <p class=" text-right">{{dashboardObj.toDoOverview.allReqQuoteApprovedNoJobCount}}</p>
                    </div>
                </div>
                <div class="mt-2 flex justify-between border-b border-gray2 pb-1">
                    <div>
                        <p class="mb-1">Job Requiring action</p>
                        <p>{{dashboardObj.toDoOverview.visitCompleteNotCreateInvoiceCount}}</p>
                    </div>
                    <div>
                        <p class="mb-1">Open Quotes</p>
                        <p class=" text-right">{{dashboardObj.toDoOverview.allOpenQuoteCount}}</p>
                    </div>
                </div>
          </div>
          <div class="p-4 card bg-white rounded-xl mb-4">
            <h3 class="text-text2 heading-3 pb-1 font-semibold">My Schedule</h3>
                
            <div class="col col-span-1 mt-8 xl:mt-4 2xl:mt-4">
              <!-- <div class="card rounded-xl p-3 px-4 bg-white flex items-center justify-between heading-5 font-semibold mb-2 text-text2"  v-show="sortedList.length > 0">
                <div>Your Assignments</div>
                <div class="text-primary cursor-pointer" @click="$router.push({name: 'schedule'})">Schedule</div>
              </div> -->
              <div class="overflow-auto" :style="`max-height:` + cardHight + `px !important;`" v-show="sortedList.length > 0" v-if="isLoading">
                <div v-for="(details, index) in sortedList" :key="index" >
                  <!-- <div class="heading-5 text-gray4 py-1 pt-2 pl-1" >
                    <span @click.stop="openMap(index, details)" class="cursor-pointer hover:text-primary transition group">
                      {{ details.secondTitle }}<i class="fa-solid fa-location-arrow pl-1 text-primary invisible group-hover:visible"></i>
                    </span>
                  </div> -->
                  <div v-if="!details.isHeading" class=" w-full rounded-md p-2 border-gray2 mb-2 text-text2  border hover:border-primary hover:text-primary cursor-pointer" @click.stop="redirectOnDetails(details)">
                    <div class="sm:flex  flex-wrap justify-between">
                      <div class="flex">
                        <div v-html="getIcons(details.entitySlug)"></div>
                        <p class="pl-1 sm:flex flex-wrap sm:items-center">
                            <span class=" whitespace-nowrap pr-4 ">{{setTimes(details.startDate, details.endDate)}}</span>
                          </p>
                      </div>
                            <span v-if="details.fromOffice" class="whitespace-nowrap ml-2 sm:ml-0">At Office</span>
                      <div class="text-primary ml-2 w-24 text-right" v-if="details.isCompleted">
                        Completed
                      </div>
                      <div v-else>
                        <p class="text-error ml-2 w-24 text-right">{{details.status}}</p>
                      </div>
                    </div>
                    <div class=" heading-6 pl-2">
                      <div class="font-semibold">{{details.title}}</div>
                    </div>
                  </div>
                  <div v-else>
                    <h3 class="text-text2 heading-3 py-2 font-semibold">Completed</h3>
                  </div>
                </div>
              </div>
              <div v-show="sortedList.length === 0" class="card rounded-xl p-2 bg-white flex" v-if="isLoading">
                <div class="text-gray4 heading-4 p-2"><i class="fa-regular fa-clipboard pr-2"></i>There is no data in your schedule list yet.</div>
              </div>
              <div v-if="!isLoading" class="card rounded-xl p-2 bg-white flex ">
                <div class="text-gray4 heading-4 p-2 flex">
                  <div class="loader2 ease-linear mr-2"></div>
                  Loading.....
                </div>
              </div>
            </div>
          </div>
          </div>
       </div>
      </div>
      
    </div>
    <loader
      v-if="$store.getters.showLoader"
      v-bind:data="$store.getters.loaderMessage"
    ></loader>
    <snakBar
        v-if="$store.getters.showAlert"
        v-bind:status="$store.getters.showAlert"
        v-bind:message="$store.getters.alertMessage"
        v-bind:color="$store.getters.alertColor"
      >
      </snakBar>
   </div>
</template>
<script>
/* global google */
import {jsCoreDateCreator} from '@/utils/convertDateAndTime.js'
// import moment from 'moment'
import moment from 'moment'
import MyJobApp from '@/api/MyJobApp.js'
import Loader from '@/View/components/Loader.vue'
import snakBar from '@/View/components/SnakBar.vue'
import {FilterPermissions} from '@/utils/Permissions.js'

export default {
  name: "landing-page",
  components: {
    Loader,
    snakBar,
  },
  data() {
    return {
      mainHeight: window.innerHeight - 200,
      isRequestPermission: false,
      isQuotePermission: false,
      isJobPermission: false,
      isInvoicePermission: false,
      isExpPermission: false,
      isShiftPermission: false,
      isTaskPermission: false,
      dataBaseUsageValueInPer: 100,
      isLoading: false,
      cardHight: 0,
      sortedList: [],
      dashboardObj: null,
      scheduleList: [],
      toDateUtc: '',
      monthFirstDay: '',
      setUsedLocation: '',
      orgDetail: {},
      currentLocation: {
        latitude: 0,
        longitude: 0,
      }
    };
  },
  created() {
    this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo')).userDetailId
  },
  mounted() {
    setTimeout(() => {
      this.isRequestPermission = FilterPermissions('request')
      this.isQuotePermission = FilterPermissions('quote')
      this.isJobPermission = FilterPermissions('job')
      this.isInvoicePermission = FilterPermissions('invoice')
      this.isExpPermission = FilterPermissions('expense')
      this.isShiftPermission = FilterPermissions('shift')
      this.isTaskPermission = FilterPermissions('task')
    }, 100);
    var date = new Date();
    let temp = new Date(date.getFullYear(), date.getMonth(), 1);
    this.monthFirstDay = new Date(temp).toUTCString();
    this.toDateUtc = new Date().toUTCString();
    this.cardHight = window.innerHeight - 600
    this.mainHeight = window.innerHeight - 110
    this.orgDetail = JSON.parse(localStorage.getItem('orgInfo'))
    console.log('this.orgDetail', this.orgDetail)
    if (this.orgDetail !== null) {
      console.log('Organization address used for tracking');
      this.currentLocation.latitude = this.orgDetail?.latitude;
      this.currentLocation.longitude = this.orgDetail?.longitude;
      this.setUsedLocation = 'Office'
    } else {
      this.setUsedLocation = 'Your'
      this.getUserLocation();
      console.log('User location used for tracking');
    }
    this.getOrgDetail()
    this.getScheduleListing()
    this.getDashboardDetail()
  },
  beforeDestroy() {
  },
  methods: {
    openMap (index, data) {
      if (data.entitySlug === 'task') {
        let temp = FilterPermissions('task')
        if (temp) {
          this.redirectTomap(index)
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Task location`})
        }
      }
      if (data.entitySlug === 'job') {
        let temp = FilterPermissions('job')
        if (temp) {
          this.redirectTomap(index)
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Job location`})
        }
      }
      if (data.entitySlug === 'request') {
        let temp = FilterPermissions('request')
        if (temp) {
          this.redirectTomap(index)
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Request location`})
        }
      }
    },
    redirectTomap (index) {
      let lat1 = index === 0 ? this.currentLocation.latitude: this.scheduleList[index - 1].latitude
      let lng1 = index === 0 ? this.currentLocation.longitude : this.scheduleList[index - 1].longitude
      let lat2 = this.scheduleList[index].latitude
      let lng2 = this.scheduleList[index].longitude 
      let url = 'https://www.google.com/maps/dir/?api=1&origin=' + lat1 + ',' + lng1 + '&destination=' + lat2 + ',' + lng2
      window.open(url, '_blank')
    },
    getUserLocation () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(currentPosition => {
          this.currentLocation.latitude = currentPosition.coords.latitude
          this.currentLocation.longitude = currentPosition.coords.latitude
        })
      }
    },
    getDashboardDetail () {
      let temp = moment(new Date()).format('YYYY-MM-DD');
      temp = temp + " 00:00"
      let startDt = new Date(temp).toUTCString();
      let endDt = new Date(new Date().setHours(23, 59, 0, 0)).toUTCString()
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.DashboardOverview(
        startDt,
        endDt,
        response => {
          this.dashboardObj = response.Data
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    // getDashboardDetail () {
    //   this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
    //   MyJobApp.DashboardDetailApi(
    //     moment(new Date()).format('YYYY-MM-DD'),
    //     this.monthFirstDay,
    //     this.toDateUtc,
    //     response => {
    //       this.dashboardObj = response.Data
    //       this.$store.dispatch('SetLoader', {status: false, message: ''})
    //     },
    //     (error) => {
    //       this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
    //       this.$store.dispatch('SetLoader', {status: false, message: ''})
    //     }
    //   )
    // },
    setTimes (start, end) {
      let displayText = '';
      const jsDate = new Date(start);
      const jeDate = new Date(end);

      if (
        jsDate.getHours() === 0 &&
        jsDate.getMinutes() === 0 &&
        jeDate.getHours() === 23 &&
        jeDate.getMinutes() === 59
      ) {
        displayText = ' All-Day';
      } else {
        displayText = ` ${moment(jsDate).format('hh:mm a')} - ${moment(
          jeDate,
        ).format('hh:mm a')}`;
      }

      return displayText;
    },
    async getScheduleListing () {
      this.isLoading = false
      this.scheduleList = []
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.DashboardAssignmentListApi(
      moment(new Date()).format('YYYY-MM-DD'),
        response => {
          let temp = response.Data !== null ? response.Data : []
          for (let index = 0; index < temp.length; index++) {
            const entityDetail = temp[index].entityDetail
              let status = ""
             if (entityDetail.isCompleted) {
              status = "Completed"
            } else if (new Date(entityDetail.startDate) <= new Date) {
              status = "Late"
            } else {
              status = "Upcoming"
            }
            
            const details = {
              isCompleted: entityDetail.isCompleted,
              status: status,
              title: entityDetail.entityTitle ? entityDetail.entityTitle: `Request for ${entityDetail.customerName} `,
              id: temp[index].entitySlug === 'job' ? entityDetail.visitId : entityDetail.entityDetailId,
              startDate: entityDetail.startDate,
              endDate: entityDetail.endDate,
              entitySlug: temp[index].entitySlug,
              strtTime: new Date(entityDetail.startDate).getTime(),
              latitude: entityDetail.latitude === 0 ? this.orgDetail.latitude : entityDetail.latitude,
              longitude: entityDetail.longitude === 0 ? this.orgDetail.longitude : entityDetail.longitude,
            };
            this.scheduleList.push(details)
          }
          this.getTaskListing()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    async getTaskListing() {
      MyJobApp.DashboardTaskListApi(
        moment(new Date()).format('YYYY-MM-DD'),
        response => {
          let tempA = response.Data !== null ? response.Data : []
          for (let index = 0; index < tempA.length; index++) {
            let element = tempA[index]
            const sTime = moment(new Date(element.startTime)).format('HH:mm:ss');
            const eTime = moment(new Date(element.endTime)).format('HH:mm:ss');
            const startDate = jsCoreDateCreator(
              `${moment(new Date(element.taskDate)).format('YYYY-MM-DD')} ${sTime}`,
            );
            const endDate = jsCoreDateCreator(
              `${moment(new Date(element.taskDate)).format('YYYY-MM-DD')} ${eTime}`,
            );

            let status = ""
            if (element.isCompleted) {
              status = "Completed"
            } else if (new Date(element.startTime) <= new Date) {
              status = "Late"
            } else {
              status = "Upcoming"
            }

            const details = {
              isCompleted: element.isCompleted,
              status: status,
              title: element.taskTitle,
              id: element.taskId,
              startDate,
              endDate,
              strtTime: new Date(startDate).getTime(),
              entitySlug: 'task',
              fromOffice: false,
              latitude: element.latitude === 0 ? this.orgDetail.latitude : element.latitude,
              longitude: element.longitude === 0 ? this.orgDetail.longitude : element.longitude,
            };
            if (element.latitude === 0 && element.longitude === 0) {
              details.fromOffice = true
            }
            this.scheduleList.push(details)
          }
          // this.sortedList = this.scheduleList
          setTimeout(() => {
            this.sortedAsc()
          }, 200);
          setTimeout(() => {
            this.sortedList = []
            let tempArr = []
              for (let index = 0; index < this.scheduleList.length; index++) {
                let temp1 = this.getDistanceUnit( this.calculateDistance(index === 0 ? this.currentLocation.latitude: this.scheduleList[index - 1].latitude, index === 0 ? this.currentLocation.longitude : this.scheduleList[index - 1].longitude, this.scheduleList[index].latitude, this.scheduleList[index].longitude, 'K',) * 1000,)
                let temp2 = ' approx'
                let temp3 = index === 0 ? ` from ${this.setUsedLocation} location` : ' in between'
                // console.log('temp2', temp1, index)
                this.scheduleList[index].distanceTitle =  temp1 + temp2 + temp3
                // console.log('this.scheduleList', this.scheduleList[index])
                let temp = this.getData(index === 0 ? this.currentLocation.latitude: this.scheduleList[index - 1].latitude, index === 0 ? this.currentLocation.longitude : this.scheduleList[index - 1].longitude, this.scheduleList[index].latitude, this.scheduleList[index].longitude, index)
                temp.then((a) => {
                  if (a.rows[0].elements[0].status === 'OK') {
                    this.sortedList[index].secondTitle = a.rows[0].elements[0].duration.text + ' drive (' + a.rows[0].elements[0].distance.text + ')' + temp3
                  } else {
                    this.sortedList[index].secondTitle = this.sortedList[index].distanceTitle
                  }
                })
                // if (index <= 4) {
                  tempArr.push(this.scheduleList[index])
                // }
              }

              const statusOrder = { "Upcoming": 1, "Late": 2, "Completed": 3 };

              // Custom sort function
              const customSort = (a, b) => {
                const orderA = statusOrder[a.status];
                const orderB = statusOrder[b.status];
                
                // Compare statuses
                if (orderA !== orderB) {
                  return orderA - orderB;
                } else {
                  // If statuses are equal, sort by startDate
                  return new Date(a.startDate) - new Date(b.startDate);
                }
              };

              // Sort the items array using the custom sort function

              let newUpdated = []


              newUpdated = tempArr.slice().sort(customSort);

              const completedTasks = newUpdated.filter(task => task.status === 'Completed');
              if (completedTasks.length > 0) {
              // Create a heading object for completed tasks
              const completedHeading = {
                  isHeading: true,
                  title: 'Completed',
                  id: 'heading_completed'
              };

                  // Add the heading to the list of tasks
                  completedTasks.unshift(completedHeading);
              }


             let  newKUpdated = newUpdated.filter(task => task.status !== 'Completed');

              this.sortedList = newKUpdated.concat(completedTasks)
              // for (let index = 0; index < 10; index++) {
              //   this.sortedList.push(this.sortedList[0])
              // }
              console.log('this.completedTasks', completedTasks)
              console.log('this.sortedList', this.sortedList)
              setTimeout(() => {
                this.isLoading = true
              }, 1000);
          }, 500);
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    async getData (lat1, lon1, lat2, lon2) {
      var p1 = new google.maps.LatLng(lat1, lon1);
      var p2 = new google.maps.LatLng(lat2, lon2);
      var service = new google.maps.DistanceMatrixService();
      return service.getDistanceMatrix({'origins': [p1], 'destinations': [p2], travelMode: google.maps.TravelMode.DRIVING}, this.callback)
    },
    callback (response, status) {
      console.log('apiDistance--statusstatusstusstatus---->',response , status)
    },
    async sortedAsc() {
      return this.scheduleList.sort((a, b) => {
          if (a.strtTime < b.strtTime) return -1;
          if (a.strtTime > b.strtTime) return 1;
          return 0;
      });
    },
    getIcons (data) {
      if (data === 'task') {
        return `<span class="text-primary heading-4"><i class="fa-solid fa-clipboard-list w-8"></i></span>`
      } else if (data === 'job') {
        return `<span class="text-primary heading-4"><i class="fa-solid fa-briefcase w-8"></i></span>`
      } else if (data === 'request') {
        return `<span class="text-primary heading-4"><i class="fa-solid fa-code-pull-request w-8"></i></span>`
      }
    },
    getDistanceUnit (distanceMeter) {
      console.log('distanceMeter', distanceMeter / 1000 >= 1000)
      let distance = '';
      if (!isNaN(distanceMeter)) {
        if (navigator.languages != undefined) {
          console.log('navigator.languages', navigator.languages)
          const countryCode = navigator.languages[0];
          switch (countryCode) {
            case 'US':
              if (distanceMeter * 0.00062137 >= 1000) {
                distance = `999+ mi`;
              } else {
                distance = `${(distanceMeter * 0.00062137).toFixed(1)} mi`;
              }
              break;
            default:
              if (distanceMeter / 1000 >= 1000) {
                distance = `999+ km`;
              } else {
                distance = `${(distanceMeter / 1000).toFixed(1)} km`;
              }
              console.log('distance12 3', distance)
            break;
          }
          return distance;
        } else {
          if (distanceMeter / 1000 >= 1000) {
            distance = `999+ km`;
          } else {
            distance = `${(distanceMeter / 1000).toFixed(1)} km`;
          }
          return distance;
        }
      } else {
        return '--'
      }
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
      // console.log('lat1', lat1)
      // console.log('lon1', lon1)
      // console.log('lat2', lat2)
      // console.log('lon2', lon2)
      const radius = 6371; // Earth's radius in km
      const dLat = this.toRadians(lat2 - lat1);
      const dLng = this.toRadians(lon2 - lon1);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.toRadians(lat1)) * Math.cos(this.toRadians(lat2)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = radius * c;

      return distance.toFixed(2);
    },
    toRadians(degree) {
      return degree * (Math.PI / 180);
    },
    redirectOnDetails (item) {
      if (item.entitySlug === 'task') {
        let temp = FilterPermissions('task')
        if (temp) {
          let date = moment(new Date(item.startDate)).format('YYYY-MM-DD')
          this.$router.push({name: 'TaskDetail', params: {taskId: item.id} , query: {date: date}})
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Task detail`})
        }
      }
      if (item.entitySlug === 'job') {
        let temp = FilterPermissions('job')
        if (temp) {
          this.$router.push({name: 'visitDetail', params: {visitId: item.id}})
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Job detail`})
        }
      }
      if (item.entitySlug === 'request') {
        let temp = FilterPermissions('request')
        if (temp) {
          this.$router.push({name: 'requestDetail', params: {requestId: item.id}})
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Request detail`})
        }
      }
    },
    getOrgDetail() {
      this.$store.dispatch("SetLoader", { status: true, message: "Loading...",});
      MyJobApp.getOrgDetail(
        (response) => {
          let orgDetail = response.Data
          let percompleted = 30;
          if (orgDetail.latitude !== 0) {
            percompleted += 40;
          }
          if (orgDetail.addressLine1 !== '') {
            percompleted += 30;
          }
          console.log('percompleted', percompleted)
          this.dataBaseUsageValueInPer = percompleted
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
  }
}
</script>

<style scoped>
.card-content {
  background-color: transparent;
  padding: 0rem;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
}
.sheets {
  border-radius: 10%;
  width: 70px !important;
  height: 67px !important;
  justify-content: center;
  display: flex;
  margin-top: -40px;
  border-color: #26c6da;
}
.hoverableCard:hover {
  box-shadow: 1px 5px 5px 1px rgba(38, 38, 38, 0.2);
  top: 0px;
  border: 0px solid #cccccc;
  /* background-color: rgb(244, 244, 244); */
  /* box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2); */
  /* transition:all 0.5s; */
  /* border: 0px solid #cccccc; */
}
.cardAbsolute {
  position: absolute;
  left: 20px;
  top: -30px;
  border-radius: 8%;
  width: 80px !important;
  height: 80px !important;
  text-align: center;
  justify-content: center;
  display: flex;
}
.marginTop {
  margin-top: 30px;
}

.card_icon{
    position: absolute;
    left: 20px;
    top: -30px;
    width: 180px!important;
    height: 50px!important;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.loader2 {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1295BA;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1.2s linear infinite;
}
.progress-bar {
  background-color: rgba(156, 156, 156, 0.691);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  height: 100%;
  background-color: rgb(18,149,186);
  transform-origin: 0% 50%;
}

</style>
